@use "src/styles/animations";
@use "src/styles/palette";
@use "src/styles/buttons";
@use "src/styles/mixins";

$colors: "primary", "secondary", "black", "white";

.button {
  position: relative;
  text-align: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
    background-color: palette.grey("border") !important;
    color: palette.grey("support-light") !important;
    &:active {
      pointer-events: none;
    }
  }
}

.button__small {
  padding: 0.5rem 1.25rem;
}

.button__medium {
  padding: 0.875rem 1.5625rem;
}

.button__wide {
  width: 100%;
}

.button__loading {
  span {
    visibility: hidden;
    opacity: 0;
  }

  pointer-events: none;
  @include animations.spinning-loader(8px, 4px);

  // center the spinner
  &:after {
    margin: auto;
  }
}

.button_link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-decoration: none;
}

/****************************************************
 * Solid buttons
 ***************************************************/
@mixin solidBrandColor($key) {
  @extend %reset-button;

  font-weight: 800;
  line-height: 1.25;
  border-radius: 2rem;
  transition: background-color 0.25s linear;
  background-color: palette.brand($key);
  color: palette.brand($key, contrast);

  &:focus-visible {
    outline: 3px solid palette.brand("secondary");
  }

  &:hover {
    background-color: palette.brand($key, light);
  }

  &:disabled {
    background-color: palette.brand($key, negative);
  }
}

.button_solid {
  @each $color in $colors {
    &__#{$color} {
      @include solidBrandColor($color);
    }
  }
}

/****************************************************
 * Outline buttons
 ***************************************************/
@mixin outlineBrandColor($key) {
  @extend %reset-button;

  font-weight: 800;
  line-height: 1.25;
  border-radius: 2rem;
  transition: border-color 0.25s linear;
  color: palette.brand($key);
  border-width: 0.125rem;
  border-style: solid;
  border-color: rgba(black, 20%);

  &:hover,
  &:disabled {
    border-color: palette.brand($key);
  }

  &:focus-visible {
    outline: 3px solid palette.brand("secondary");
  }
}

.button_outline {
  @each $variant in $colors {
    &__#{$variant} {
      @include outlineBrandColor($variant);
    }
  }
}

/****************************************************
 * Text buttons
 ***************************************************/
@mixin textBrandColor($key) {
  @extend %reset-button;

  font-weight: 800;
  line-height: 1.25;
  color: palette.brand($key);
  padding-left: 0;
  padding-right: 0;
}

.button_text {
  @each $variant in $colors {
    &__#{$variant} {
      @include textBrandColor($variant);
    }
  }
}

[data-theme="750"] {
  .button__theme {
    --750-gradient: linear-gradient(-30deg, var(--750-red), var(--750-blue));

    color: black;
    border: 2px solid transparent;
    background:
      linear-gradient(white, white) padding-box,
      var(--750-gradient) border-box;
    background-size: 100% 100%;
    position: relative;
    transition: background-position 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 2rem;
      background: var(--750-gradient);
      background-size: 100% 100%;
      z-index: -1;
      opacity: 0;
      transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &:hover {
      color: white;
      background: transparent;
      &:before {
        opacity: 1;
      }
    }
    &.active {
      color: white;
      background:
        var(--750-gradient) padding-box,
        var(--750-gradient) border-box;
      background-size: 100% 100%;
      &:hover {
        background-position: 100%;
      }
      &:has(.cross) {
        padding-right: 0.6rem;
      }
    }
  }
}
