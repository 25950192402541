@use "src/styles/cards";
@use "src/styles/palette";

$transition: 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

.bellow_default {
  border-bottom: 1px solid currentColor;

  &:first-of-type {
    border-top: 1px solid currentColor;
  }
}

.bellow_card {
  @extend .card__flat;

  padding: 0 1.5rem;
  margin-bottom: 0.625rem;
  background-color: palette.grey(light);
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  transition:
    background-color $transition,
    border-color $transition;

  &__active {
    background-color: palette.$white;
    border-color: palette.grey(border);
  }
}

.bellow_header {
  padding: 1.25rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.bellow_header_icon {
  margin-left: auto;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &_open {
    @extend .bellow_header_icon;

    transform: rotate(180deg);
  }
}

.bellow_header_title {
  font-weight: 700;
  margin: 0;
  font-size: 1rem;
}

.bellow_body_wrapper {
  overflow: hidden;
  transition:
    height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    visibility 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.bellow_body_wrapper_closed {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &.bellow_body_wrapper_open {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}

.bellow_body {
  @extend .paragraph;
  padding-bottom: 0.625rem;
  font-size: 1rem;
}

.bellow_body_item {
  padding: 0.625rem 0;
}
