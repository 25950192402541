@use "src/styles/mixins";

.link {
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;

  > span {
    @include mixins.text-underline-target();
  }

  &.hover,
  &:hover {
    > span {
      @include mixins.text-underline-trigger();
    }
  }

  &.focus,
  &:focus {
    outline-offset: 0.25rem;
  }
}

.external {
  &:after {
    content: "";
    display: inline-block;
    height: 0.875rem;
    width: 0.875rem;
    background-color: currentColor;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='black' d='M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z'/%3E%3C/svg%3E");
  }
}

.underline {
  text-decoration: underline;

  // Disable underline animation
  &:hover {
    > span {
      background-size: 0;
    }
  }
}
