@use "src/styles/layout";
@use "src/styles/palette";
@use "src/styles/typography";

.content {
  @each $number in (1, 2, 3, 4, 5) {
    h#{$number} {
      @if $number >= 3 {
        @extend .header_3;
      } @else {
        @extend .header_#{$number};
      }

      margin-bottom: 0.875rem;
    }
  }

  a {
    color: var(--black);
  }

  p {
    @extend .paragraph;
    margin-bottom: 1rem;
  }

  strong {
    font-weight: 800;
  }

  ul,
  ol {
    margin: 0 0 1.5rem 0;
    padding: 0 0 0 1rem;

    li {
      @extend .paragraph;

      margin-bottom: 0.25rem;
    }
  }

  .tableWrapper {
    @media (max-width: layout.breakpoint("md")) {
      max-width: 100%;
      overflow-x: auto;
      margin-bottom: 2rem;
    }
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
    overflow: hidden;

    p {
      margin: 0;
      color: var(--black);
    }
    
    caption {
      text-align: left;
    }

    th, td {
      padding: 1rem;
      text-align: left;
      border-bottom: 1px solid palette.grey("border");
    }

    th {
      @extend .header_5;
      background-color: palette.grey("border");
      padding: 1.5rem 1rem;

      @media (max-width: layout.breakpoint("md")) {
        padding: 1rem;
      }
    }

    tr:first-child th {
      border-top: none;
    }

    tr:hover {
      background-color: palette.grey("border");
    }
  }
}
