@use "src/styles/layout";
@use "src/styles/palette";

.header {
  position: relative;
}

.content_wrapper {
  @include layout.container();

  display: flex;
  padding: 0 1rem;
  align-items: center;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  // predefined min-height to prevent layout shift
  min-height: 40px;

  img {
    width: 100%;
    height: auto;
  }

  @media (min-width: layout.breakpoint(md)) {
    justify-content: flex-start;
  }
}

.logo_text {
  font-size: 0.5rem;
  color: palette.grey(dark);
  line-height: 1rem;
}

.mobile_favourite_button {
  margin-left: auto;
  display: flex;
  column-gap: 0.5rem;

  @media (min-width: layout.breakpoint(md)) {
    display: none;
  }

  button.theme {
    width: 2.8125rem;
    height: 2.8125rem;
    background-color: palette.grey(light);
    border: 2px solid palette.grey(border);
  
    svg {
      fill: var(--black);
    }
  
    &:hover {
      background-color: palette.grey(base);
    }
  }
}

.bottom_line {
  height: 1px;
  width: 100%;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}

[data-theme="750"] {
  .mobile_favourite_button {
    button.theme {
      background: var(--750-gradient-02);
      border: none;

      svg {
        fill: var(--white);
      }
    }
  }
}
