@use "src/styles/palette";

.search_button {
  background-color: palette.grey(light);
  width: 2.8125rem;
  height: 2.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  border: 2px solid palette.grey(border);

  &:hover {
    background-color: palette.grey(base);
  }
}

.search {
  width: 1.2rem;
  height: 1.2rem;
}
